import React from 'react';
import { NavLink } from 'react-router-dom';

import { getFrontOfficePath } from 'components/App/utils';
import { useTranslation } from 'components/Localization/Localisation';
import CircleInfoIcon from 'images/icons/circle-info-solid.svg';
import { FrontOfficeRoutes } from 'router/routes';

import './GoAccesibilityLink.css';

export const GoAccesibilityLink: React.FC = () => {
  const { t } = useTranslation();

  return (
    <NavLink
      data-testid="go-accessibility-link"
      to={getFrontOfficePath(FrontOfficeRoutes.ACCESSIBILITY)}
      className="go-accessibility-link-container"
    >
      <div className="go-accessibility-container">
        <span className="go-accessibility-icon">
          <img src={CircleInfoIcon} alt="go-accessibility-icon" />
        </span>
        <span>{t('accessibility.link')}</span>
      </div>
    </NavLink>
  );
};
