import React from 'react';

import { GoAccesibilityLink } from 'components/GoAccesibilityLink/GoAccesibilityLink';

import './SiteFooter.css';

export const SiteFooter: React.FC = () => {
  return (
    <div className="site-footer-container">
      <GoAccesibilityLink />
    </div>
  );
};
