import React from 'react';

import { getFrontOfficePath } from 'components/App/utils';
import { GoBackLink } from 'components/GoBackLink';
import { useTranslation } from 'components/Localization/Localisation';
import { Title } from 'components/UI/Title/Title';
import { FrontOfficeRoutes } from 'router/routes';

import './Accessibility.css';

export const Accessibility: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="accessibility-container">
      <GoBackLink to={getFrontOfficePath(FrontOfficeRoutes.HOME)} />

      <Title title={t('accessibility.title')} />

      <p>{t('accessibility.commitment')}</p>
      <p>{t('accessibility.strategy')}</p>

      <Title title={t('accessibility.compliance_status.title')} size="small" />
      <p>{t('accessibility.compliance_status.description')}</p>

      <Title title={t('accessibility.test_results.title')} size="small" />
      <p>{t('accessibility.test_results.description')}</p>
      <ul>
        <li>{t('accessibility.test_results.criteria_met')}</li>
        <li>{t('accessibility.test_results.criteria_not_met')}</li>
        <li>{t('accessibility.test_results.criteria_not_applicable')}</li>
      </ul>

      <Title title={t('accessibility.inaccessible_content.title')} size="small" />
      <p>{t('accessibility.inaccessible_content.description')}</p>

      <Title title={t('accessibility.non_compliance.title')} size="small" />
      <p>{t('accessibility.non_compliance.description')}</p>
      <ul>
        <li>{t('accessibility.non_compliance.item1')}</li>
        <li>{t('accessibility.non_compliance.item2')}</li>
        <li>{t('accessibility.non_compliance.item3')}</li>
        <li>{t('accessibility.non_compliance.item4')}</li>
        <li>{t('accessibility.non_compliance.item5')}</li>
        <li>{t('accessibility.non_compliance.item6')}</li>
      </ul>

      <Title title={t('accessibility.exemptions.title')} size="small" />
      <p>{t('accessibility.exemptions.description')}</p>

      <Title title={t('accessibility.feedback.title')} size="small" />
      <p>{t('accessibility.feedback.description')}</p>
    </div>
  );
};
